import React, { useState, useMemo } from 'react';
import {
  Box, Heading, Wrap, HStack, Text, VStack, useColorModeValue, Input, Select,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  Button, useDisclosure, Link, IconButton, Tooltip, Grid, GridItem, Textarea, Flex
} from '@chakra-ui/react';
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons';
import { FaWhatsapp, FaTelegram } from 'react-icons/fa';

interface Contact {
  id: string;
  name: string;
  email: string;
  phone: string;
  whatsapp?: string;
  telegram?: string;
}

interface ReminderItem {
  id: string;
  title: string;
  status: string;
  time: string;
  isUrgent?: boolean;
  hasBeenFollowedUp?: boolean;
  contactId: string;
  removalNote?: string;
}

const ReminderCard: React.FC<{ reminder: ReminderItem; contact: Contact; onOpen: (id: string) => void }> = ({ reminder, contact, onOpen }) => (
  <Box
    borderWidth="1px"
    borderRadius="lg"
    p={4}
    w="200px"
    minW="200px"
    h="150px"
    boxShadow="md"
    bg={reminder.isUrgent ? "red.50" : "white"}
    onClick={() => onOpen(reminder.id)}
    cursor="pointer"
  >
    <VStack align="start" spacing={2} h="100%">
      <Heading size="sm">{reminder.title}</Heading>
      <Text fontSize="sm">Status: {reminder.status}</Text>
      <Text fontSize="sm">Time: {reminder.time}</Text>
      <Text fontSize="sm">Contact: {contact.name}</Text>
      {reminder.hasBeenFollowedUp && <Text fontSize="sm" color="green.500">Followed up</Text>}
    </VStack>
  </Box>
);

const Reminders: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [contacts, setContacts] = useState<Contact[]>([
    { id: '1', name: 'John Doe', email: 'john@example.com', phone: '+1234567890', whatsapp: '+1234567890', telegram: '@johndoe' },
    { id: '2', name: 'Jane Smith', email: 'jane@example.com', phone: '+0987654321', whatsapp: '+0987654321' },
    { id: '3', name: 'Alice Johnson', email: 'alice@example.com', phone: '+1122334455', telegram: '@alicejohnson' },
  ]);
  const [reminders, setReminders] = useState<ReminderItem[]>([
    { id: '1', title: 'Project Deadline', status: 'Due Soon', time: 'Tomorrow', isUrgent: true, contactId: '1' },
    { id: '2', title: 'Client Meeting', status: 'Upcoming', time: '2:00 PM Today', isUrgent: true, contactId: '2' },
    { id: '3', title: 'Team Standup', status: 'Daily', time: '10:00 AM', contactId: '3' },
    { id: '4', title: 'Lunch Break', status: 'Daily', time: '12:30 PM', contactId: '1' },
    { id: '5', title: 'Code Review', status: 'Scheduled', time: '3:00 PM', contactId: '2' },
    { id: '6', title: 'Exercise', status: 'Personal', time: '6:00 PM', contactId: '3' },
  ]);
  const [selectedReminder, setSelectedReminder] = useState<ReminderItem | null>(null);
  const [removalNote, setRemovalNote] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isRemoveModalOpen,
    onOpen: onRemoveModalOpen,
    onClose: onRemoveModalClose
  } = useDisclosure();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const filterReminders = (reminders: ReminderItem[]) => {
    return reminders.filter(reminder =>
      (statusFilter === 'All' || reminder.status === statusFilter) &&
      (reminder.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        reminder.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        reminder.time.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contacts.find(c => c.id === reminder.contactId)?.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  };

  const filteredReminders = filterReminders(reminders);

  const allStatuses = useMemo(() => {
    const statuses = ['All'];
    const statusSet: { [key: string]: boolean } = { 'All': true };
    reminders.forEach(reminder => {
      if (!statusSet[reminder.status]) {
        statuses.push(reminder.status);
        statusSet[reminder.status] = true;
      }
    });
    return statuses;
  }, [reminders]);

  const handleReminderClick = (id: string) => {
    const reminder = reminders.find(r => r.id === id);
    if (reminder) {
      setSelectedReminder(reminder);
      onOpen();
    }
  };

  const handleFollowUp = () => {
    if (selectedReminder) {
      setReminders(reminders.map(r =>
        r.id === selectedReminder.id ? { ...r, hasBeenFollowedUp: true } : r
      ));
      onClose();
    }
  };

  const handleRemoveFollowUp = () => {
    if (selectedReminder) {
      setReminders(reminders.map(r =>
        r.id === selectedReminder.id ? { ...r, hasBeenFollowedUp: false, removalNote } : r
      ));
      setRemovalNote('');
      onRemoveModalClose();
      onClose();
    }
  };

  const getContactForReminder = (reminderId: string) => {
    const reminder = reminders.find(r => r.id === reminderId);
    return contacts.find(c => c.id === reminder?.contactId);
  };

  return (
    <Box maxWidth="1200px" margin="auto" padding={6} bg={bgColor}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading as="h1" size="xl">Reminders</Heading>
      </Flex>
      <VStack spacing={6} align="stretch">
        <HStack spacing={4}>
          <Input
            placeholder="Search reminders..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            flex={1}
          />
          <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} width="200px">
            {allStatuses.map(status => (
              <option key={status} value={status}>{status}</option>
            ))}
          </Select>
        </HStack>
        <Box borderWidth="1px" borderRadius="lg" p={4} boxShadow="md" borderColor={borderColor}>
          <Heading as="h3" size="md" marginBottom={4} textAlign="left">
            Upcoming Reminders
          </Heading>
          <Wrap spacing={4}>
            {filteredReminders.map((reminder) => (
              <ReminderCard
                key={reminder.id}
                reminder={reminder}
                contact={getContactForReminder(reminder.id)!}
                onOpen={handleReminderClick}
              />
            ))}
          </Wrap>
        </Box>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedReminder?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text>Status: {selectedReminder?.status}</Text>
              <Text>Time: {selectedReminder?.time}</Text>
              {selectedReminder && (
                <>
                  <Text fontWeight="bold">Contact: {getContactForReminder(selectedReminder.id)?.name}</Text>
                  <Grid templateColumns="auto 1fr auto" gap={4} alignItems="center">
                    <GridItem>
                      <Tooltip label="Call">
                        <IconButton
                          aria-label="Call"
                          icon={<PhoneIcon />}
                          as={Link}
                          href={`tel:${getContactForReminder(selectedReminder.id)?.phone}`}
                        />
                      </Tooltip>
                    </GridItem>
                    <GridItem>
                      <Text>{getContactForReminder(selectedReminder.id)?.phone}</Text>
                    </GridItem>
                    <GridItem>
                      <Button size="sm" onClick={() => navigator.clipboard.writeText(getContactForReminder(selectedReminder.id)?.phone || '')}>Copy</Button>
                    </GridItem>

                    <GridItem>
                      <Tooltip label="Email">
                        <IconButton
                          aria-label="Email"
                          icon={<EmailIcon />}
                          as={Link}
                          href={`mailto:${getContactForReminder(selectedReminder.id)?.email}`}
                        />
                      </Tooltip>
                    </GridItem>
                    <GridItem>
                      <Text>{getContactForReminder(selectedReminder.id)?.email}</Text>
                    </GridItem>
                    <GridItem>
                      <Button size="sm" onClick={() => navigator.clipboard.writeText(getContactForReminder(selectedReminder.id)?.email || '')}>Copy</Button>
                    </GridItem>

                    {getContactForReminder(selectedReminder.id)?.whatsapp && (
                      <>
                        <GridItem>
                          <Tooltip label="WhatsApp">
                            <IconButton
                              aria-label="WhatsApp"
                              icon={<FaWhatsapp />}
                              as={Link}
                              href={`https://wa.me/${getContactForReminder(selectedReminder.id)?.whatsapp}`}
                              isExternal
                            />
                          </Tooltip>
                        </GridItem>
                        <GridItem>
                          <Text>{getContactForReminder(selectedReminder.id)?.whatsapp}</Text>
                        </GridItem>
                        <GridItem>
                          <Button size="sm" onClick={() => navigator.clipboard.writeText(getContactForReminder(selectedReminder.id)?.whatsapp || '')}>Copy</Button>
                        </GridItem>
                      </>
                    )}

                    {getContactForReminder(selectedReminder.id)?.telegram && (
                      <>
                        <GridItem>
                          <Tooltip label="Telegram">
                            <IconButton
                              aria-label="Telegram"
                              icon={<FaTelegram />}
                              as={Link}
                              href={`https://t.me/${getContactForReminder(selectedReminder.id)?.telegram}`}
                              isExternal
                            />
                          </Tooltip>
                        </GridItem>
                        <GridItem>
                          <Text>{getContactForReminder(selectedReminder.id)?.telegram}</Text>
                        </GridItem>
                        <GridItem>
                          <Button size="sm" onClick={() => navigator.clipboard.writeText(getContactForReminder(selectedReminder.id)?.telegram || '')}>Copy</Button>
                        </GridItem>
                      </>
                    )}
                  </Grid>
                </>
              )}
              <Button onClick={handleFollowUp} colorScheme="green">
                Mark as followed up
              </Button>
              <Button onClick={onRemoveModalOpen} colorScheme="red">
                Remove from follow-up
              </Button>
              {selectedReminder?.removalNote && (
                <Box borderWidth="1px" borderRadius="md" p={3} bg="gray.100">
                  <Text fontWeight="bold">Removal Note:</Text>
                  <Text>{selectedReminder.removalNote}</Text>
                </Box>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isRemoveModalOpen} onClose={onRemoveModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove from Follow-up</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Text>Please provide a reason for removing this reminder from follow-up:</Text>
              <Textarea
                value={removalNote}
                onChange={(e) => setRemovalNote(e.target.value)}
                placeholder="Enter removal reason here..."
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleRemoveFollowUp}>
              Confirm Removal
            </Button>
            <Button variant="ghost" onClick={onRemoveModalClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Reminders;