import React, { useState, useMemo, useRef } from 'react';
import {
    Box, Heading, VStack, HStack, Text, Input, Select, Button, Tag, TagLabel,
    useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
    Tabs, TabList, TabPanels, Tab, TabPanel, Flex, FormControl, FormLabel,
    Wrap, useColorModeValue, Image, IconButton, Drawer, DrawerBody, DrawerHeader,
    DrawerOverlay, DrawerContent, DrawerCloseButton, Link, Tooltip, TagCloseButton
} from '@chakra-ui/react';
import { EditIcon, AddIcon, CloseIcon, AttachmentIcon, DownloadIcon } from '@chakra-ui/icons';
import { FaFile, FaImage } from 'react-icons/fa';


interface FileMetadata {
    key: string;
    value: string;
}

interface File {
    id: string;
    name: string;
    url: string;
    type: string;
    metadata: FileMetadata[];
}

interface Customer {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    whatsapp?: string;
    telegram?: string;
    tags: string[];
    pictureUrl?: string;
    files: File[];
}

interface ReminderItem {
    id: string;
    title: string;
    status: string;
    time: string;
    isUrgent?: boolean;
    hasBeenFollowedUp?: boolean;
    customerId: string;
    removalNote?: string;
}

const initialCustomers: Customer[] = [
    {
        id: '1',
        firstName: 'John',
        lastName: 'Doe',
        email: 'john@example.com',
        phone: '+1234567890',
        whatsapp: '+1234567890',
        telegram: '@johndoe',
        tags: ['VIP', 'Tech'],
        pictureUrl: 'https://via.placeholder.com/150',
        files: []
    },
    {
        id: '2',
        firstName: 'Jane',
        lastName: 'Smith',
        email: 'jane@example.com',
        phone: '+0987654321',
        whatsapp: '+0987654321',
        tags: ['New', 'Finance'],
        pictureUrl: 'https://via.placeholder.com/150',
        files: []
    },
    {
        id: '3',
        firstName: 'Alice',
        lastName: 'Johnson',
        email: 'alice@example.com',
        phone: '+1122334455',
        telegram: '@alicejohnson',
        tags: ['Healthcare'],
        pictureUrl: 'https://via.placeholder.com/150',
        files: []
    },
];

const suggestedMetadataFields = [
    {
        key: 'documentType',
        values: ['Contract', 'Invoice', 'Report', 'Presentation', 'Other']
    },
    {
        key: 'status',
        values: ['Draft', 'In Review', 'Approved', 'Rejected']
    },
    {
        key: 'department',
        values: ['Sales', 'Marketing', 'Finance', 'HR', 'IT']
    },
    {
        key: 'priority',
        values: ['Low', 'Medium', 'High', 'Urgent']
    }
];

const initialReminders: ReminderItem[] = [
    { id: '1', title: 'Follow-up Call', status: 'Pending', time: 'Tomorrow 2 PM', isUrgent: true, customerId: '1' },
    { id: '2', title: 'Contract Renewal', status: 'Upcoming', time: 'Next Week', customerId: '2' },
    { id: '3', title: 'Product Demo', status: 'Scheduled', time: 'Friday 10 AM', customerId: '3' },
];

const CustomerPortal: React.FC = () => {
    const [customers, setCustomers] = useState<Customer[]>(initialCustomers);
    const [reminders, setReminders] = useState<ReminderItem[]>(initialReminders);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterTag, setFilterTag] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [newTag, setNewTag] = useState('');
    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const { isOpen: isNewCustomerModalOpen, onOpen: onNewCustomerModalOpen, onClose: onNewCustomerModalClose } = useDisclosure();
    const bgColor = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const fileInputRef = useRef<HTMLInputElement>(null);
    const pictureInputRef = useRef<HTMLInputElement>(null);
    const [newMetadataKey, setNewMetadataKey] = useState<string>('');
    const [newMetadataValue, setNewMetadataValue] = useState<string>('');
    const [isCustomKey, setIsCustomKey] = useState<boolean>(false);

    const filteredCustomers = useMemo(() => {
        return customers.filter(customer =>
            (customer.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                customer.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                customer.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))) &&
            (filterTag === '' || customer.tags.includes(filterTag))
        );
    }, [customers, searchTerm, filterTag]);

    const allTags = useMemo(() => {
        const tags = new Set<string>();
        customers.forEach(customer => customer.tags.forEach(tag => tags.add(tag)));
        return Array.from(tags);
    }, [customers]);

    const handleCustomerSelect = (customer: Customer) => {
        setSelectedCustomer(customer);
        setEditMode(false);
        onDrawerOpen();
    };

    const handleEditCustomer = () => {
        setEditMode(true);
    };

    const handleSaveCustomer = () => {
        if (selectedCustomer) {
            setCustomers(customers.map(c => c.id === selectedCustomer.id ? selectedCustomer : c));
            setEditMode(false);
        }
    };

    const handleAddTag = () => {
        if (selectedCustomer && newTag) {
            const updatedCustomer = {
                ...selectedCustomer,
                tags: [...selectedCustomer.tags, newTag]
            };
            setSelectedCustomer(updatedCustomer);
            setNewTag('');
        }
    };

    const handleRemoveTag = (tagToRemove: string) => {
        if (selectedCustomer) {
            const updatedCustomer = {
                ...selectedCustomer,
                tags: selectedCustomer.tags.filter(tag => tag !== tagToRemove)
            };
            setSelectedCustomer(updatedCustomer);
        }
    };
    const handleAddReminder = () => {
        if (selectedCustomer) {
            const newReminder: ReminderItem = {
                id: (reminders.length + 1).toString(),
                title: 'New Reminder',
                status: 'Pending',
                time: 'Not set',
                customerId: selectedCustomer.id
            };
            setReminders([...reminders, newReminder]);
        }
    };

    const handlePictureUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && selectedCustomer) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const updatedCustomer = {
                    ...selectedCustomer,
                    pictureUrl: reader.result as string
                };
                setSelectedCustomer(updatedCustomer);
                setCustomers(customers.map(c => c.id === selectedCustomer.id ? updatedCustomer : c));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && selectedCustomer) {
            const newFile: File = {
                id: Date.now().toString(),
                name: file.name,
                url: URL.createObjectURL(file),
                type: file.type,
                metadata: [] // Initialize with empty metadata array
            };
            const updatedCustomer = {
                ...selectedCustomer,
                files: [...selectedCustomer.files, newFile]
            };
            setSelectedCustomer(updatedCustomer);
            setCustomers(customers.map(c => c.id === selectedCustomer.id ? updatedCustomer : c));
        }
    };
    const handleAddFileMetadata = (fileId: string) => {
        if (selectedCustomer && newMetadataKey.trim() !== '' && newMetadataValue.trim() !== '') {
            const updatedFiles = selectedCustomer.files.map(file => {
                if (file.id === fileId) {
                    return {
                        ...file,
                        metadata: [...file.metadata, { key: newMetadataKey.trim(), value: newMetadataValue.trim() }]
                    };
                }
                return file;
            });
            const updatedCustomer = {
                ...selectedCustomer,
                files: updatedFiles
            };
            setSelectedCustomer(updatedCustomer);
            setCustomers(customers.map(c => c.id === selectedCustomer.id ? updatedCustomer : c));
            setNewMetadataKey('');
            setNewMetadataValue('');
            setIsCustomKey(false);
        }
    };

    const handleRemoveFileMetadata = (fileId: string, metadataKey: string) => {
        if (selectedCustomer) {
            const updatedFiles = selectedCustomer.files.map(file => {
                if (file.id === fileId) {
                    return {
                        ...file,
                        metadata: file.metadata.filter(m => m.key !== metadataKey)
                    };
                }
                return file;
            });
            const updatedCustomer = {
                ...selectedCustomer,
                files: updatedFiles
            };
            setSelectedCustomer(updatedCustomer);
            setCustomers(customers.map(c => c.id === selectedCustomer.id ? updatedCustomer : c));
        }
    };

    const handleMetadataKeyChange = (value: string) => {
        setNewMetadataKey(value);
        setNewMetadataValue('');
        setIsCustomKey(value === 'custom');
    };

    const handleRemoveFile = (fileId: string) => {
        if (selectedCustomer) {
            const updatedFiles = selectedCustomer.files.filter(file => file.id !== fileId);
            const updatedCustomer = {
                ...selectedCustomer,
                files: updatedFiles
            };
            setSelectedCustomer(updatedCustomer);
            setCustomers(customers.map(c => c.id === selectedCustomer.id ? updatedCustomer : c));
        }
    };

    const [newCustomer, setNewCustomer] = useState<Partial<Customer>>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        whatsapp: '',
        telegram: '',
        tags: [],
    });

    const handleNewCustomerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewCustomer({ ...newCustomer, [name]: value });
    };

    const handleNewCustomerTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tags = e.target.value.split(',').map(tag => tag.trim());
        setNewCustomer({ ...newCustomer, tags });
    };

    const handleAddNewCustomer = () => {
        const id = (customers.length + 1).toString();
        const newCustomerWithId: Customer = {
            id,
            firstName: newCustomer.firstName || '',
            lastName: newCustomer.lastName || '',
            email: newCustomer.email || '',
            phone: newCustomer.phone || '',
            whatsapp: newCustomer.whatsapp,
            telegram: newCustomer.telegram,
            tags: newCustomer.tags || [],
            pictureUrl: 'https://via.placeholder.com/150',
            files: []
        };
        setCustomers([...customers, newCustomerWithId]);
        setNewCustomer({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            whatsapp: '',
            telegram: '',
            tags: [],
        });
        onNewCustomerModalClose();
    };

    const handleDownloadFile = (file: File) => {
        // Create a temporary anchor element
        const element = document.createElement('a');
        element.href = file.url;
        element.download = file.name;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    return (
        <Box maxWidth="1200px" margin="auto" padding={6} bg={bgColor}>
            <Flex justifyContent="space-between" alignItems="center" mb={6}>
                <Heading as="h1" size="xl">Customer Portal</Heading>
                <Button leftIcon={<AddIcon />} colorScheme="green" onClick={onNewCustomerModalOpen}>
                    Add New Customer
                </Button>
            </Flex>

            <HStack spacing={4} mb={6}>
                <Input
                    placeholder="Search customers..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    width="300px"
                />
                <Select
                    placeholder="Filter by tag"
                    value={filterTag}
                    onChange={(e) => setFilterTag(e.target.value)}
                    width="200px"
                >
                    {allTags.map(tag => (
                        <option key={tag} value={tag}>{tag}</option>
                    ))}
                </Select>
            </HStack>

            <Wrap spacing={4}>
                {filteredCustomers.map(customer => (
                    <Box
                        key={customer.id}
                        p={3}
                        borderWidth="1px"
                        borderRadius="md"
                        cursor="pointer"
                        onClick={() => handleCustomerSelect(customer)}
                        bg={selectedCustomer?.id === customer.id ? 'blue.100' : 'white'}
                        width="250px"
                    >
                        <VStack align="start" spacing={2}>
                            <Image
                                src={customer.pictureUrl || 'https://via.placeholder.com/150'}
                                alt={`${customer.firstName} ${customer.lastName}`}
                                borderRadius="full"
                                boxSize="100px"
                                objectFit="cover"
                            />
                            <Text fontWeight="bold">{`${customer.firstName} ${customer.lastName}`}</Text>
                            <Wrap>
                                {customer.tags.map(tag => (
                                    <Tag key={tag} size="sm" colorScheme="blue">
                                        <TagLabel>{tag}</TagLabel>
                                    </Tag>
                                ))}
                            </Wrap>
                        </VStack>
                    </Box>
                ))}
            </Wrap>
            <Modal isOpen={isNewCustomerModalOpen} onClose={onNewCustomerModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Customer</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <FormControl isRequired>
                                <FormLabel>First Name</FormLabel>
                                <Input name="firstName" value={newCustomer.firstName} onChange={handleNewCustomerChange} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Last Name</FormLabel>
                                <Input name="lastName" value={newCustomer.lastName} onChange={handleNewCustomerChange} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Email</FormLabel>
                                <Input name="email" type="email" value={newCustomer.email} onChange={handleNewCustomerChange} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Phone</FormLabel>
                                <Input name="phone" value={newCustomer.phone} onChange={handleNewCustomerChange} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>WhatsApp</FormLabel>
                                <Input name="whatsapp" value={newCustomer.whatsapp} onChange={handleNewCustomerChange} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Telegram</FormLabel>
                                <Input name="telegram" value={newCustomer.telegram} onChange={handleNewCustomerChange} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Tags (comma-separated)</FormLabel>
                                <Input name="tags" value={newCustomer.tags?.join(', ')} onChange={handleNewCustomerTagChange} />
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleAddNewCustomer}>
                            Add Customer
                        </Button>
                        <Button variant="ghost" onClick={onNewCustomerModalClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="xl">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Customer Details</DrawerHeader>
                    <DrawerBody>
                        {selectedCustomer && (
                            <Box borderWidth="1px" borderRadius="lg" p={6} bg={bgColor} borderColor={borderColor}>
                                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                                    <Heading as="h2" size="lg">{`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}</Heading>
                                    <Button leftIcon={<EditIcon />} onClick={handleEditCustomer} disabled={editMode}>
                                        Edit
                                    </Button>
                                </Flex>
                                <Tabs>
                                    <TabList>
                                        <Tab>Details</Tab>
                                        <Tab>Historical Reminders</Tab>
                                        <Tab>Medical Cards</Tab>
                                        <Tab>Records Files</Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <VStack spacing={4} align="stretch">
                                                <FormControl>
                                                    <FormLabel>Picture</FormLabel>
                                                    <Image
                                                        src={selectedCustomer.pictureUrl || 'https://via.placeholder.com/150'}
                                                        alt={`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
                                                        borderRadius="full"
                                                        boxSize="150px"
                                                        objectFit="cover"
                                                        mb={2}
                                                    />
                                                    {editMode && (
                                                        <Button onClick={() => pictureInputRef.current?.click()}>
                                                            Upload New Picture
                                                        </Button>
                                                    )}
                                                    <Input
                                                        type="file"
                                                        accept="image/*"
                                                        ref={pictureInputRef}
                                                        onChange={handlePictureUpload}
                                                        display="none"
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>First Name</FormLabel>
                                                    <Input
                                                        value={selectedCustomer.firstName}
                                                        isReadOnly={!editMode}
                                                        onChange={(e) => setSelectedCustomer({ ...selectedCustomer, firstName: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Last Name</FormLabel>
                                                    <Input
                                                        value={selectedCustomer.lastName}
                                                        isReadOnly={!editMode}
                                                        onChange={(e) => setSelectedCustomer({ ...selectedCustomer, lastName: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Email</FormLabel>
                                                    <Input
                                                        value={selectedCustomer.email}
                                                        isReadOnly={!editMode}
                                                        onChange={(e) => setSelectedCustomer({ ...selectedCustomer, email: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Phone</FormLabel>
                                                    <Input
                                                        value={selectedCustomer.phone}
                                                        isReadOnly={!editMode}
                                                        onChange={(e) => setSelectedCustomer({ ...selectedCustomer, phone: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>WhatsApp</FormLabel>
                                                    <Input
                                                        value={selectedCustomer.whatsapp || ''}
                                                        isReadOnly={!editMode}
                                                        onChange={(e) => setSelectedCustomer({ ...selectedCustomer, whatsapp: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Telegram</FormLabel>
                                                    <Input
                                                        value={selectedCustomer.telegram || ''}
                                                        isReadOnly={!editMode}
                                                        onChange={(e) => setSelectedCustomer({ ...selectedCustomer, telegram: e.target.value })}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Tags</FormLabel>
                                                    <Wrap mb={2}>
                                                        {selectedCustomer.tags.map(tag => (
                                                            <Tag key={tag} size="md" colorScheme="blue" variant="solid">
                                                                <TagLabel>{tag}</TagLabel>
                                                                {editMode && (
                                                                    <TagCloseButton onClick={() => handleRemoveTag(tag)} />
                                                                )}
                                                            </Tag>
                                                        ))}
                                                    </Wrap>
                                                    {editMode && (
                                                        <HStack>
                                                            <Input
                                                                placeholder="Add new tag"
                                                                value={newTag}
                                                                onChange={(e) => setNewTag(e.target.value)}
                                                            />
                                                            <Button onClick={handleAddTag}>Add</Button>
                                                        </HStack>
                                                    )}
                                                </FormControl>
                                                {editMode && (
                                                    <Button colorScheme="blue" onClick={handleSaveCustomer}>
                                                        Save Changes
                                                    </Button>
                                                )}
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <VStack spacing={4} align="stretch">
                                                <Button colorScheme="green" onClick={handleAddReminder}>
                                                    Add New Reminder
                                                </Button>
                                                {reminders
                                                    .filter(reminder => reminder.customerId === selectedCustomer.id)
                                                    .map(reminder => (
                                                        <Box
                                                            key={reminder.id}
                                                            p={3}
                                                            borderWidth="1px"
                                                            borderRadius="md"
                                                            bg={reminder.isUrgent ? "red.50" : "white"}
                                                        >
                                                            <Text fontWeight="bold">{reminder.title}</Text>
                                                            <Text>Status: {reminder.status}</Text>
                                                            <Text>Time: {reminder.time}</Text>
                                                            {reminder.hasBeenFollowedUp && (
                                                                <Text color="green.500">Followed up</Text>
                                                            )}
                                                        </Box>
                                                    ))}
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <VStack spacing={4} align="stretch">
                                                <Flex justifyContent="space-between" alignItems="center">
                                                    <Heading as="h3" size="md">Attached Files</Heading>
                                                    <Button
                                                        leftIcon={<AttachmentIcon />}
                                                        onClick={() => fileInputRef.current?.click()}
                                                        colorScheme="blue"
                                                    >
                                                        Upload File
                                                    </Button>
                                                    <Input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        onChange={handleFileUpload}
                                                        display="none"
                                                    />
                                                </Flex>
                                                {selectedCustomer.files.length === 0 ? (
                                                    <Text>No files attached</Text>
                                                ) : (
                                                    <VStack align="stretch" spacing={6}>
                                                        {selectedCustomer.files.map(file => (
                                                            <Box key={file.id} borderWidth="1px" borderRadius="md" p={4}>
                                                                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                                                                    <HStack>
                                                                        {file.type.startsWith('image/') ? (
                                                                            <FaImage />
                                                                        ) : (
                                                                            <FaFile />
                                                                        )}
                                                                        <Link href={file.url} isExternal>
                                                                            {file.name}
                                                                        </Link>
                                                                    </HStack>
                                                                    <HStack>
                                                                        <Tooltip label="Download file">
                                                                            <IconButton
                                                                                aria-label="Download file"
                                                                                icon={<DownloadIcon />}
                                                                                size="sm"
                                                                                onClick={() => handleDownloadFile(file)}
                                                                            />
                                                                        </Tooltip>
                                                                        <Tooltip label="Remove file">
                                                                            <IconButton
                                                                                aria-label="Remove file"
                                                                                icon={<CloseIcon />}
                                                                                size="sm"
                                                                                onClick={() => handleRemoveFile(file.id)}
                                                                            />
                                                                        </Tooltip>
                                                                    </HStack>
                                                                </Flex>

                                                                {file.type.startsWith('image/') && (
                                                                    <Image
                                                                        src={file.url}
                                                                        alt={file.name}
                                                                        maxH="200px"
                                                                        objectFit="contain"
                                                                        mb={4}
                                                                    />
                                                                )}

                                                                <VStack align="stretch" spacing={2}>
                                                                    {file.metadata.map(meta => (
                                                                        <Flex key={meta.key} justifyContent="space-between" alignItems="center">
                                                                            <Text><strong>{meta.key}:</strong> {meta.value}</Text>
                                                                            <IconButton
                                                                                aria-label="Remove metadata"
                                                                                icon={<CloseIcon />}
                                                                                size="xs"
                                                                                onClick={() => handleRemoveFileMetadata(file.id, meta.key)}
                                                                            />
                                                                        </Flex>
                                                                    ))}
                                                                </VStack>

                                                                <VStack mt={4} align="stretch">
                                                                    <Select
                                                                        placeholder="Select metadata field"
                                                                        value={newMetadataKey}
                                                                        onChange={(e) => handleMetadataKeyChange(e.target.value)}
                                                                    >
                                                                        {suggestedMetadataFields.map(field => (
                                                                            <option key={field.key} value={field.key}>{field.key}</option>
                                                                        ))}
                                                                        <option value="custom">Custom Field</option>
                                                                    </Select>

                                                                    {isCustomKey ? (
                                                                        <Input
                                                                            placeholder="Custom field name"
                                                                            value={newMetadataKey}
                                                                            onChange={(e) => setNewMetadataKey(e.target.value)}
                                                                        />
                                                                    ) : null}

                                                                    {newMetadataKey && !isCustomKey ? (
                                                                        <Select
                                                                            placeholder="Select value"
                                                                            value={newMetadataValue}
                                                                            onChange={(e) => setNewMetadataValue(e.target.value)}
                                                                        >
                                                                            {suggestedMetadataFields.find(field => field.key === newMetadataKey)?.values.map(value => (
                                                                                <option key={value} value={value}>{value}</option>
                                                                            ))}
                                                                            <option value="custom">Custom Value</option>
                                                                        </Select>
                                                                    ) : (
                                                                        <Input
                                                                            placeholder="Value"
                                                                            value={newMetadataValue}
                                                                            onChange={(e) => setNewMetadataValue(e.target.value)}
                                                                        />
                                                                    )}

                                                                    <Button
                                                                        onClick={() => handleAddFileMetadata(file.id)}
                                                                        isDisabled={!newMetadataKey || !newMetadataValue}
                                                                    >
                                                                        Add Metadata
                                                                    </Button>
                                                                </VStack>
                                                            </Box>
                                                        ))}
                                                    </VStack>
                                                )}
                                            </VStack>
                                        </TabPanel>
                                        <TabPanel>
                                            <VStack spacing={4} align="stretch">
                                                <Flex justifyContent="space-between" alignItems="center">
                                                    <Heading as="h3" size="md">Attached Files</Heading>
                                                    <Button
                                                        leftIcon={<AttachmentIcon />}
                                                        onClick={() => fileInputRef.current?.click()}
                                                        colorScheme="blue"
                                                    >
                                                        Upload File
                                                    </Button>
                                                    <Input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        onChange={handleFileUpload}
                                                        display="none"
                                                    />
                                                </Flex>
                                                {selectedCustomer.files.length === 0 ? (
                                                    <Text>No files attached</Text>
                                                ) : (
                                                    <VStack align="stretch" spacing={6}>
                                                        {selectedCustomer.files.map(file => (
                                                            <Box key={file.id} borderWidth="1px" borderRadius="md" p={4}>
                                                                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                                                                    <HStack>
                                                                        {file.type.startsWith('image/') ? (
                                                                            <FaImage />
                                                                        ) : (
                                                                            <FaFile />
                                                                        )}
                                                                        <Link href={file.url} isExternal>
                                                                            {file.name}
                                                                        </Link>
                                                                    </HStack>
                                                                    <HStack>
                                                                        <Tooltip label="Download file">
                                                                            <IconButton
                                                                                aria-label="Download file"
                                                                                icon={<DownloadIcon />}
                                                                                size="sm"
                                                                                onClick={() => handleDownloadFile(file)}
                                                                            />
                                                                        </Tooltip>
                                                                        <Tooltip label="Remove file">
                                                                            <IconButton
                                                                                aria-label="Remove file"
                                                                                icon={<CloseIcon />}
                                                                                size="sm"
                                                                                onClick={() => handleRemoveFile(file.id)}
                                                                            />
                                                                        </Tooltip>
                                                                    </HStack>
                                                                </Flex>

                                                                {file.type.startsWith('image/') && (
                                                                    <Image
                                                                        src={file.url}
                                                                        alt={file.name}
                                                                        maxH="200px"
                                                                        objectFit="contain"
                                                                        mb={4}
                                                                    />
                                                                )}

                                                                <VStack align="stretch" spacing={2}>
                                                                    {file.metadata.map(meta => (
                                                                        <Flex key={meta.key} justifyContent="space-between" alignItems="center">
                                                                            <Text><strong>{meta.key}:</strong> {meta.value}</Text>
                                                                            <IconButton
                                                                                aria-label="Remove metadata"
                                                                                icon={<CloseIcon />}
                                                                                size="xs"
                                                                                onClick={() => handleRemoveFileMetadata(file.id, meta.key)}
                                                                            />
                                                                        </Flex>
                                                                    ))}
                                                                </VStack>

                                                                <VStack mt={4} align="stretch">
                                                                    <Select
                                                                        placeholder="Select metadata field"
                                                                        value={newMetadataKey}
                                                                        onChange={(e) => handleMetadataKeyChange(e.target.value)}
                                                                    >
                                                                        {suggestedMetadataFields.map(field => (
                                                                            <option key={field.key} value={field.key}>{field.key}</option>
                                                                        ))}
                                                                        <option value="custom">Custom Field</option>
                                                                    </Select>

                                                                    {isCustomKey ? (
                                                                        <Input
                                                                            placeholder="Custom field name"
                                                                            value={newMetadataKey}
                                                                            onChange={(e) => setNewMetadataKey(e.target.value)}
                                                                        />
                                                                    ) : null}

                                                                    {newMetadataKey && !isCustomKey ? (
                                                                        <Select
                                                                            placeholder="Select value"
                                                                            value={newMetadataValue}
                                                                            onChange={(e) => setNewMetadataValue(e.target.value)}
                                                                        >
                                                                            {suggestedMetadataFields.find(field => field.key === newMetadataKey)?.values.map(value => (
                                                                                <option key={value} value={value}>{value}</option>
                                                                            ))}
                                                                            <option value="custom">Custom Value</option>
                                                                        </Select>
                                                                    ) : (
                                                                        <Input
                                                                            placeholder="Value"
                                                                            value={newMetadataValue}
                                                                            onChange={(e) => setNewMetadataValue(e.target.value)}
                                                                        />
                                                                    )}

                                                                    <Button
                                                                        onClick={() => handleAddFileMetadata(file.id)}
                                                                        isDisabled={!newMetadataKey || !newMetadataValue}
                                                                    >
                                                                        Add Metadata
                                                                    </Button>
                                                                </VStack>
                                                            </Box>
                                                        ))}
                                                    </VStack>
                                                )}
                                            </VStack>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Box>
                        )}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    );
};

export default CustomerPortal;