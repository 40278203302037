import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    Box, Heading, VStack, Input, Button, Table,
    Thead, Tbody, Tr, Th, Td, useDisclosure, Modal, ModalOverlay,
    ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
    FormControl, FormLabel, useToast, useColorModeValue, Flex, Wrap, Tag, TagLabel, TagCloseButton, HStack
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { directus } from '../../../api';
import { FollowUpRules } from '../../../interfaces';
import { readItems, createItem, updateItem, deleteItem } from '@directus/sdk';

interface TagRule {
    id: number;
    name: string;
    tags: string[];
    followUpDays: number;
}

const ReminderRules: React.FC = () => {
    const directus_collection = 'follow_up_rules';
    const [tagRules, setTagRules] = useState<TagRule[]>([]);

    // value of modal 
    const [newRule, setNewRule] = useState('');
    const [newTags, setNewTags] = useState([] as string[]);
    const [newTag, setNewTag] = useState('');
    const [newFollowUpDays, setNewFollowUpDays] = useState('');

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isEdit, setEdit] = useState(false);
    const toast = useToast();
    const bgColor = useColorModeValue('white', 'gray.800');

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = useForm();

    async function getCurrentTags() {
        try {
            const currentFollowUpRules = await directus.request((readItems as any)(directus_collection));
            return currentFollowUpRules as FollowUpRules[];
        } catch (error) {
            console.error('Error fetching tags:', error);
            throw error;
        }
    }

    useEffect(() => {
        setTagRules([]);
        const fetchData = async () => {
            try {
                const followUpRules = await getCurrentTags();
                if (followUpRules.length > 0) {
                    let tags = [] as TagRule[];
                    followUpRules.forEach(followUpRule => {
                        tags = [...tags, {
                            id: followUpRule.id,
                            name: followUpRule.name as string,
                            tags: followUpRule.auto_card_name_policy as string[],
                            followUpDays: 30,
                        }]
                    })
                    setTagRules(tags);
                } else {
                    console.log('First name not available');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchData(); // Call the async function
    }, []);

    const handleAddRule = () => {
        if (!newRule || !newTags || !newFollowUpDays) {
            toast({
                title: "Error",
                description: "Please fill in all fields.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const followUpDays = parseInt(newFollowUpDays, 10);
        if (isNaN(followUpDays) || followUpDays <= 0) {
            toast({
                title: "Error",
                description: "Follow-up days must be a positive number.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (newTags.length <= 0) {
            toast({
                title: "Error",
                description: "Tags is required please add them in.",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            return;
        }

        const existingRule = tagRules.find(rule => rule.name.toLowerCase() === newRule.toLowerCase());
        if (existingRule) {
            return new Promise<void>(() => {
                setTimeout(async () => {
                    try {
                        await directus.request<FollowUpRules>((updateItem as any)(directus_collection, existingRule.id, {
                            auto_card_name_policy: newTags, duration: followUpDays
                        }));
                        setTagRules(prev => prev.map(rule =>
                            rule.name.toLowerCase() === newRule.toLowerCase()
                                ? { ...rule, tags: newTags, followUpDays }
                                : rule
                        ));
                        toast({
                            title: "Rule Updated",
                            description: `Updated follow-up rule for name "${newRule}".`,
                            status: "info",
                            duration: 3000,
                            isClosable: true,
                        });
                    } catch (error) {
                        reset()
                        toast({
                            title: "Failed to edit rule!",
                            description: `Failed to add rule because of "${error}, most likely no permission"`,
                            status: "error",
                            duration: 3000,
                            isClosable: true
                        })
                        return;
                    }
                    handleAbortEvent();
                }, 60)

            })
        } else {
            return new Promise<void>((resolve) => {
                setTimeout(async () => {
                    try {
                        const result = await directus.request<FollowUpRules>((createItem as any)(directus_collection, {
                            name: newRule, auto_card_name_policy: newTags, duration: followUpDays
                        }));
                        setTagRules(prev => [...prev, { id: result.id, name: newRule, tags: newTags, followUpDays }]);
                        toast({
                            title: "Rule Added",
                            description: `Added new follow-up rule for name "${newRule}".`,
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                        });
                    } catch (error) {
                        reset()
                        toast({
                            title: "Failed to add rule!",
                            description: `Failed to add rule because of "${error}, most likely no permission"`,
                            status: "error",
                            duration: 3000,
                            isClosable: true
                        })
                        return;
                    }
                }, 60)
                handleAbortEvent();
            })
        }
    };

    const handleAddTag = () => {
        setNewTags([...newTags, newTag]);
        setNewTag('');
    };

    const handleRemoveTag = (tagToRemove: string) => {
        setNewTags([...newTags.filter(tag => tag !== tagToRemove)])
    };

    const handleDeleteRule = (name: string, id: number) => {
        return new Promise<void>(() => {
            setTimeout(async () => {
                try {
                    await directus.request<FollowUpRules>((deleteItem as any)(directus_collection, id))
                    setTagRules(prev => prev.filter(rule => rule.id !== id));
                    toast({
                        title: "Rule Deleted",
                        description: `Deleted follow-up rule for name ${name}, id "${id}".`,
                        status: "warning",
                        duration: 3000,
                        isClosable: true,
                    });
                } catch (error) {
                    reset()
                    toast({
                        title: "Failed to delete rule!",
                        description: `Failed to delete rule because of "${error}, most likely no permission"`,
                        status: "error",
                        duration: 3000,
                        isClosable: true
                    })
                    return;
                }
            }, 60)
        })
    };

    const handleEditRule = (rule: TagRule) => {
        setNewRule(rule.name);
        setNewTags(rule.tags);
        setNewFollowUpDays(rule.followUpDays.toString());
        setEdit(true);
        onOpen();
    };

    const handleAbortEvent = () => {
        setNewRule('');
        setNewTag('');
        setNewTags([]);
        setNewFollowUpDays('');
        setEdit(false);
        onClose();
        reset();
    }

    return (
        <Box maxWidth="1200px" margin="auto" padding={6} bg={bgColor}>
            <Flex justifyContent="space-between" alignItems="center" mb={6}>
                <Heading as="h1" size="xl">Reminder Rules</Heading>
                <Button leftIcon={<AddIcon />} colorScheme="green" onClick={onOpen} mb={6}>
                    Add New Reminder Rule
                </Button>
            </Flex>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Tag</Th>
                        <Th>Follow-up After (Days)</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {tagRules.map(rule => (
                        <Tr key={rule.name}>
                            <Td>{rule.name}</Td>
                            <Td>
                                <Wrap mb={2}>
                                    {rule.tags.map(tag => (
                                        <Tag key={tag} size="md" colorScheme="blue" variant="solid">
                                            <TagLabel>{tag}</TagLabel>
                                        </Tag>
                                    ))}
                                </Wrap>
                            </Td>
                            <Td>{rule.followUpDays}</Td>
                            <Td>
                                <Wrap mb={2}>
                                    <Button colorScheme="blue" size="sm" onClick={() => handleEditRule(rule)}>
                                        Edit
                                    </Button>
                                    <Button colorScheme="red" size="sm" onClick={() => handleDeleteRule(rule.name, rule.id)}>
                                        Delete
                                    </Button>
                                </Wrap>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            <Modal isOpen={isOpen} onClose={handleAbortEvent}>
                <form onSubmit={handleSubmit(handleAddRule)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add/Update Tag Rule</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack spacing={4}>
                                <FormControl isRequired>
                                    <FormLabel>Name</FormLabel>
                                    <Input
                                        value={newRule}
                                        isDisabled={isEdit}
                                        onChange={(e) => setNewRule(e.target.value)}
                                        placeholder="e.g., Dental Reminder"
                                    />
                                </FormControl>
                                <FormControl isRequired isInvalid={newTags.length === 0}>
                                    <FormLabel>Tags</FormLabel>
                                    <Wrap mb={2}>
                                        {newTags.map(tag => (
                                            <Tag key={tag} size="md" colorScheme="blue" variant="solid">
                                                <TagLabel>{tag}</TagLabel>
                                                <TagCloseButton onClick={() => handleRemoveTag(tag)} />
                                            </Tag>
                                        ))}
                                    </Wrap>
                                    <HStack>
                                        <Input
                                            isRequired={false}
                                            aria-required={false}
                                            placeholder="Add new tag"
                                            value={newTag}
                                            onChange={(e) => setNewTag(e.target.value)}
                                        />
                                        <Button onClick={handleAddTag}>Add</Button>
                                    </HStack>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Follow-up After (Days)</FormLabel>
                                    <Input
                                        type="number"
                                        value={newFollowUpDays}
                                        onChange={(e) => setNewFollowUpDays(e.target.value)}
                                        placeholder="e.g., 7"
                                    />
                                </FormControl>
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} type="submit" isLoading={isSubmitting}>
                                Add/Update Rule
                            </Button>
                            <Button variant="ghost" isLoading={isSubmitting} onClick={handleAbortEvent}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
        </Box>
    );
};

export default ReminderRules;