import { useState } from "react";
import { useForm } from 'react-hook-form';
import { useAuth } from '../context'; 
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Text,
  FormControl,
  FormErrorMessage,
  InputRightElement,
  Image
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit,
    register,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm()

  const auth = useAuth();

  function onSubmit(values: any) {
    return new Promise<void>((resolve) => {
      setTimeout(async () => {
        if (values.email !== "" && values.password !== "") {
          const reqStatus = await auth.loginAction(values);
          if (!reqStatus) {
            // reset the status
            reset();

            // set error status
            setError('root', {
              type: 'manual',
              message: errors instanceof Error ? errors.message : 'Login failed! Please re-check your email and password!'
            });
          }
          return;
        }
      }, 60)
    })
  }
  const handleShowClick = () => setShowPassword(!showPassword);

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
      borderRight="1px"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          boxSize='180px'
          borderRadius={5}
          objectFit='cover'
          src='/logo512.png'
          alt='Dan Abramov'
        />
        <Text color="teal.400">Medical Record System</Text>
        <Box minW={{ base: "90%", md: "468px" }} borderRadius={5}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl isInvalid={errors.email as any}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input type="email" placeholder="Email Address" {...register('email', {
                      required: 'Wacao, please fill in the Email!',
                    })}/>
                </InputGroup>
                <FormErrorMessage>
                    {
                      errors.email ? 
                      <>{errors.email.message as string}</>
                      : <></>
                    }
                  </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.password as any}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    {...register('password', {
                      required: 'Wacao, please fill in the Password!',
                    })}
                  />
                  <br></br>
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                    {
                      errors.password ? 
                      <>{errors.password.message as string}</>
                      : <></>
                    }
                  </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.root as any}>
                <Button
                  borderRadius={5}
                  type="submit"
                  variant="solid"
                  colorScheme="teal"
                  width="full"
                  isLoading={isSubmitting}
                >
                  Login
                </Button>
                <FormErrorMessage>
                  {
                    errors.root ? 
                    <>{errors.root.message as string}</>
                    : <></>
                  }
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;