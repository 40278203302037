import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context'; 

const PrivateRoute: React.FC = () => {
    const { token } = useAuth(); 
    if ( token ) {
        if ( 'access_token' in token ) {
            if ( 'expires_at' in token ) {
                if (Number(token['expires_at']) !== 0 && (Number(token['expires_at']) >= (Date.now() - 1000))) {
                    return <Outlet />;
                }
            }
        }
    }
    return <Navigate to="/login" />;
};

export default PrivateRoute;