import React from 'react';
import { Box, Heading, Wrap, HStack, Text, VStack, Flex, Grid, GridItem, useColorModeValue } from '@chakra-ui/react';

interface BarChartProps {
  data: Array<{ date: string; value: number; followUps: number }>;
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const maxValue = Math.max(...data.map(item => item.value));
  
  return (
    <Box width="100%" padding={4}>
      <HStack h="200px" alignItems="flex-end" spacing={2} position="relative">
        {data.map(({ date, value, followUps }, index) => (
          <VStack key={index} alignItems="center" spacing={1} height="100%" justifyContent="flex-end">
            <Box w="40px" h={`${(value / maxValue) * 100}%`} bg="blue.500" position="relative">
              <Text 
                position="absolute" 
                top="-20px" 
                left="50%" 
                transform="translateX(-50%)" 
                fontSize="xs"
                fontWeight="bold"
              >
                {value}
              </Text>
            </Box>
            <Text fontSize="xs" transform="rotate(-45deg)" transformOrigin="top left" width="40px" textAlign="right">
              {date}
            </Text>
            <Text fontSize="xs" fontWeight="bold">Follow-ups: {followUps}</Text>
          </VStack>
        ))}
        {/* Y-axis */}
        <Box position="absolute" left="-30px" top="0" bottom="0" width="30px">
          {[0, 25, 50, 75, 100].map((tick) => (
            <Text 
              key={tick} 
              position="absolute" 
              right="5px" 
              bottom={`${tick}%`} 
              fontSize="xs" 
              transform="translateY(50%)"
            >
              {Math.round((maxValue * tick) / 100)}
            </Text>
          ))}
        </Box>
      </HStack>
      {/* X-axis label */}
      <Text textAlign="center" mt={4} fontSize="sm">Date</Text>
    </Box>
  );
};

interface ReminderCardProps {
  title: string;
  status: string;
  time: string;
  isUrgent?: boolean;
}

const ReminderCard: React.FC<ReminderCardProps> = ({ title, status, time, isUrgent }) => (
  <Box 
    borderWidth="1px" 
    borderRadius="lg" 
    p={4} 
    w="200px"
    minW="200px"
    h="150px"
    boxShadow="md"
    bg={isUrgent ? "red.50" : "white"}
  >
    <VStack align="start" spacing={2} h="100%">
      <Heading size="sm">{title}</Heading>
      <Text fontSize="sm">Status: {status}</Text>
      <Text fontSize="sm">Time: {time}</Text>
    </VStack>
  </Box>
);

interface Reminder extends ReminderCardProps {}

const Home: React.FC = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const urgentReminders: Reminder[] = [
    { title: 'Project Deadline', status: 'Due Soon', time: 'Tomorrow', isUrgent: true },
    { title: 'Client Meeting', status: 'Upcoming', time: '2:00 PM Today', isUrgent: true },
  ];

  const dailyReminders: Reminder[] = [
    { title: 'Team Standup', status: 'Daily', time: '10:00 AM' },
    { title: 'Lunch Break', status: 'Daily', time: '12:30 PM' },
    { title: 'Code Review', status: 'Scheduled', time: '3:00 PM' },
    { title: 'Exercise', status: 'Personal', time: '6:00 PM' },
    { title: 'Exercise', status: 'Personal', time: '6:00 PM' },
    { title: 'Exercise', status: 'Personal', time: '6:00 PM' },
    { title: 'Exercise', status: 'Personal', time: '6:00 PM' },
    { title: 'Exercise', status: 'Personal', time: '6:00 PM' },
    { title: 'Exercise', status: 'Personal', time: '6:00 PM' },
    { title: 'Exercise', status: 'Personal', time: '6:00 PM' },
    { title: 'Exercise', status: 'Personal', time: '6:00 PM' },
  ];

  const graphData = [
    { date: '09/17', value: 30, followUps: 3 },
    { date: '09/18', value: 50, followUps: 5 },
    { date: '09/19', value: 70, followUps: 7 },
    { date: '09/20', value: 60, followUps: 6 },
    { date: '09/21', value: 80, followUps: 8 },
  ];

  const currentDate = new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

  return (
    <Box maxWidth="1200px" margin="auto" padding={6} bg={bgColor}>
      <VStack spacing={6} align="stretch">
        <Box borderWidth="1px" borderRadius="lg" p={4} boxShadow="md" borderColor={borderColor}>
          <Heading as="h1" size="xl" marginBottom={2} textAlign="center">
            Welcome to RaphaRecords
          </Heading>
          <Text mb={2}>Stay organized and boost your productivity with our intuitive dashboard.</Text>
          <Text mb={2}>Track your progress, manage tasks, and stay on top of your schedule effortlessly.</Text>
          <Text>Let's make today a great day for achievement and growth!</Text>
        </Box>
        <VStack spacing={6} align="stretch">
            <Box borderWidth="1px" borderRadius="lg" p={4} boxShadow="md" borderColor={borderColor}>
                <Grid templateColumns="1fr 2fr 1fr" gap={6}>
                    <GridItem>
                        <Box borderWidth="1px" borderRadius="lg" p={4} boxShadow="md" borderColor={borderColor}>
                        <Heading as="h2" size="lg" marginBottom={2} textAlign="left">
                            Weekly Progress
                        </Heading>
                        <Text>Your performance has improved by 15% this week.</Text>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box borderWidth="1px" borderRadius="lg" p={4} boxShadow="md" borderColor={borderColor}>
                        <Heading as="h3" size="md" marginBottom={4} textAlign="left">
                            Performance Chart
                        </Heading>
                        <BarChart data={graphData} />
                        <Text mt={2} fontSize="sm" color="gray.600" textAlign="center">{currentDate}</Text>
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
        </VStack>
        <Box borderWidth="1px" borderRadius="lg" p={4} boxShadow="md" borderColor={borderColor}>
          <Heading as="h3" size="md" marginBottom={4} textAlign="left">
            Upcoming Reminder
          </Heading>
          <VStack align="stretch" spacing={4}>
            <Box>
              <Heading as="h4" size="sm" mb={2} textAlign="left">Urgent</Heading>
              <Flex overflowX="auto" pb={2}>
                <HStack spacing={4}>
                  {urgentReminders.map((reminder, index) => (
                    <ReminderCard key={`urgent-${index}`} {...reminder} />
                  ))}
                </HStack>
              </Flex>
            </Box>
            <Box>
              <Heading as="h4" size="sm" mb={2} textAlign="left">Daily</Heading>
              <Wrap spacing={4}>
                {dailyReminders.map((reminder, index) => (
                  <ReminderCard key={`daily-${index}`} {...reminder} />
                ))}
              </Wrap>
            </Box>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default Home;